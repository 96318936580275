import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import useWindowSize from '../hooks/useWindowSize'
import ConfettiGenerator from 'confetti-js'
import rehypeReact from 'rehype-react'
import ReLink from '../components/rehype/Link'

import Page from '../components/Page'
import Container from '../components/elements/Container'
import SEO from '../components/SEO'

import styles from '../scss/pages/welcome-you.module.scss'

const WelcomePage = ({ data }) => {
  const { welcomePage, allTeam, markdownRemark } = data
  const size = useWindowSize()

  useEffect(() => {
    const confettiSettings = {
      target: 'confetti-holder',
      max: size.width > 900 ? 150 : 80,
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],

        [2, 180, 146],
        [38, 209, 209],
        [136, 204, 5],
        [255, 192, 16],
      ],
      width: size.width,
      height: size.height,
      rotate: true,
    }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()

    return () => confetti.clear()
  }, [size]) // Array ensures that effect is only run on state change

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { a: ReLink, img: InlineImage },
  }).Compiler

  return (
    <Page className="p-welcome">
      <SEO title={markdownRemark.frontmatter.title} />

      <canvas
        id="confetti-holder"
        style={{
          zIndex: 1,
          position: 'fixed',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      />

      <section className="c-section c-section--pageHeader c-section--pageHeader--background">
        <Container className="c-section__outer">
          <div className="c-section__inner text-center">
            <h1 className={styles.title}>
              Congratulations {welcomePage.name}!
            </h1>
            <h3 className={styles.subTitle}>
              We’ve heard you’ve got a new job?
            </h3>
            <span className={styles.subTitle}>&#127881;</span>
          </div>
        </Container>

        <div className="c-wave c-wave--bottom">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none' }}
              d="M-5.92,115.95 C125.56,-215.63 292.04,320.22 500.84,21.20 L500.00,150.00 L0.00,150.00 Z"
            ></path>
          </svg>
        </div>
      </section>

      <section className="">
        <Container>
          <p className={styles.intro}>
            Congratulations on being hired as part of our growing team here at
            Erudus! We are sure that your talents, knowledge and attitude will
            be an asset to the company and we want you to enjoy your time with
            us. Our team is here to support you on your journey and they have
            written a little something about themselves below so you can get
            acquainted with them before you come into the office. Welcome to the
            family.
          </p>
          <h2 className={styles.teamHeader}>Meet the Team</h2>
          <ul className={styles.teamList}>
            {allTeam.nodes.map(
              ({ id, name, jobTitle, image, textNode, linkedin }) => (
                <li key={id} className={styles.teamListItem}>
                  <div className={styles.teamMember}>
                    <Img
                      fixed={image.fixed}
                      className={styles.teamMemberImage}
                      style={{
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                      }}
                      title={image.title}
                      alt={image.alt}
                    />
                    <div className={styles.teamMemberBio}>
                      <h3 className={styles.teamMemberName}>{name}</h3>
                      <p>{jobTitle}</p>
                      <div className={styles.teamMemberBlurb}>
                        {renderAst(textNode.childMarkdownRemark.htmlAst)}
                      </div>
                      {linkedin && (
                        <ul className={styles.socialList}>
                          <li>
                            <a
                              href={linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.socialLink}
                            >
                              <span className="sr-only">LinkedIn</span>
                              <svg
                                className={styles.socialIcon}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        </Container>
      </section>

      <section>
        <Container>
          <h3 className={styles.signOff}>
            Welcome to the team, we’re all really looking forward to working
            with you!
          </h3>
          <span className={styles.signOff}>&#127881;</span>
        </Container>
      </section>
    </Page>
  )
}

export default WelcomePage

export const pageQuery = graphql`
  query WelcomePageQuery($slug: String) {
    welcomePage: datoCmsWelcomePage(slug: { eq: $slug }) {
      name
      slug
    }
    allTeam: allDatoCmsTeam(sort: { fields: position }) {
      nodes {
        id
        name
        jobTitle
        image {
          title
          alt
          fixed(imgixParams: { q: 90, w: "500" }) {
            ...GatsbyDatoCmsFixed
          }
        }
        textNode {
          childMarkdownRemark {
            htmlAst
          }
        }
        linkedin
      }
    }
    markdownRemark(frontmatter: { path: { eq: "/welcome-you" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`

const InlineImage = ({ ...props }) => {
  return <img {...props} className={styles.inlineImage} loading="lazy" />
}
