import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { SkipNavContent } from '@reach/skip-nav'
import Main from './elements/Main'

const Page = ({ children, className, datoIndex, ...props }) => {
  // Set noindex by default
  if (!datoIndex) {
    props = { 'data-datocms-noindex': true, ...props }
  }

  return (
    <Main className={cn(className)} {...props}>
      <SkipNavContent data-datocms-noindex />
      {children}
    </Main>
  )
}

Page.defaultProps = {
  className: '',
  datoIndex: false,
}

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  datoIndex: PropTypes.bool,
}

export default Page
